import axios from "axios";

function closeVotePopup(popup) {
  const closeButtons = document.querySelectorAll(
    '[data-js="BookVotePopupClose"]'
  );
  const popupSuccess = popup.querySelector('[data-js="BookVotePopupSuccess"]');
  const errorHolder = popup.querySelector('[data-js="BookVotePopupError"]');
  const emailInput = popup.querySelector('[data-js="BookVotePopupEmailInput"]');
  const popupContent = popup.querySelector('[data-js="BookVotePopupContent"]');

  closeButtons.forEach(button => {
    button.addEventListener("click", e => {
      e.preventDefault();
      document.querySelector("body").style.overflow = "auto";
      popup.classList.add("hidden");

      setTimeout(() => {
        popupSuccess.classList.add("hidden");
        errorHolder.classList.add("hidden");
        emailInput.value = "";
        popupContent.classList.remove("hidden");
      }, 500);
    });
  });
}

function openVotePopup(popup) {
  const openButtons = document.querySelectorAll(
    '[data-js="BookVotePopupOpen"]'
  );

  openButtons.forEach(button => {
    button.addEventListener("click", e => {
      e.preventDefault();
      document.querySelector("body").style.overflow = "hidden";
      setVotePopupContent(button.dataset);
      popup.classList.remove("hidden");
    });
  });
}

function setVotePopupContent(bookData) {
  const {
    voteBookTitle,
    voteBookAuthor,
    voteBookImageUrl,
    voteBookId,
    voteBookPublisher
  } = bookData;

  const popup = document.querySelector('[data-js="BookVotePopupContent"]');

  const popupTitle = popup.querySelector('[data-js="BookVotePopupTitle"]');
  const popupAuthor = popup.querySelector('[data-js="BookVotePopupAuthor"]');
  const popupImage = popup.querySelector('[data-js="BookVotePopupImage"]');
  const popupBookId = popup.querySelector('[data-js="BookVotePopupBookId"]');
  const popupPublisher = popup.querySelector(
    '[data-js="BookVotePopupPublisher"]'
  );

  popupTitle.innerHTML = voteBookTitle;
  popupAuthor.innerHTML = voteBookAuthor;
  popupPublisher.innerHTML = voteBookPublisher;
  popupImage.src = voteBookImageUrl;
  popupBookId.value = voteBookId;
}

function toggleSubmitButton() {
  const checkbox = document.querySelector('[data-js="BookVotePopupCheckbox"]');
  const submitButton = document.querySelector(
    '[data-js="BookVotePopupSubmitButton"]'
  );
  submitButton.classList.add("opacity-50", "pointer-events-none");

  checkbox.addEventListener("change", e => {
    if (e.target.checked) {
      submitButton.classList.remove("opacity-50", "pointer-events-none");
      return true;
    } else {
      submitButton.classList.add("opacity-50", "pointer-events-none");
      return false;
    }
  });
}

function toggleBookVoteTermsPopup() {
  const termsPopup = document.querySelector('[data-js="BookVotePopupTerms"]');
  let isOpen = false;

  const termsPopupToggle = Array.from(
    document.querySelectorAll('[data-js="BookVotePopupTermsToggle"]')
  );

  termsPopupToggle.forEach(button => {
    button.addEventListener("click", e => {
      e.preventDefault();

      if (!isOpen) {
        termsPopup.classList.replace("hidden", "flex");
        isOpen = true;
      } else {
        termsPopup.classList.replace("flex", "hidden");
        isOpen = false;
      }
    });
  });
}

function postUserVote(popup) {
  const form = popup.querySelector('[data-js="VoteForm"]');

  form.addEventListener("submit", e => {
    e.preventDefault();

    const formData = new FormData(form);
    const errorHolder = popup.querySelector('[data-js="BookVotePopupError"]');

    form.classList.add("opacity-50", "pointer-events-none");

    axios
      .post("/wp-json/selma-stories/book-vote", formData)
      .then(response => {
        if (response.data.success) {
          form.classList.remove("opacity-50", "pointer-events-none");

          const successHolder = popup.querySelector(
            '[data-js="BookVotePopupSuccess"]'
          );
          const successMessage = successHolder.querySelector(
            '[data-js="BookVotePopupSuccessMessage"]'
          );
          const popupContent = popup.querySelector(
            '[data-js="BookVotePopupContent"]'
          );
          successMessage.innerHTML = response.data.message;
          popupContent.classList.add("hidden");
          successHolder.classList.remove("hidden");
          errorHolder.classList.replace("block", "hidden");
        } else {
          errorHolder.innerHTML = response.data.message;
          errorHolder.classList.remove("hidden");
          form.classList.remove("opacity-50", "pointer-events-none");
        }
      })
      .catch(error => {
        form.classList.remove("opacity-50", "pointer-events-none");
        errorHolder.innerHTML = "Något gick fel, försök igen.";
        errorHolder.classList.remove("hidden");
      });
  });
}

function initBookVotes() {
  const popup = document.querySelector('[data-js="BookVotePopup"]');

  if (popup) {
    openVotePopup(popup);
    closeVotePopup(popup);
    postUserVote(popup);
    toggleSubmitButton();
    toggleBookVoteTermsPopup();
  }
}

export default initBookVotes;
