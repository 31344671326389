function Accordions() {
  let accordions = Array.from(document.querySelectorAll(".accordion"));

  accordions.forEach(accordion => {
    let rows = Array.from(accordion.children).filter(el =>
      el.matches(".accordion__row")
    );

    rows.forEach(row => {
      row
        .querySelector(".accordion__row-toggle")
        .addEventListener("click", e => {
          row.classList.toggle("accordion__row--open");
        });
    });
  });

  let smAccordions = Array.from(document.querySelectorAll(".sm\\:accordion"));
  window.screen.width >= 640
    ? smAccordions.forEach(accordion => {
        let rows = Array.from(
          accordion.querySelectorAll(".sm\\:accordion__row")
        );

        rows.forEach(row => {
          row
            .querySelector(".sm\\:accordion__row-toggle")
            .addEventListener("click", e => {
              row.classList.toggle("sm:accordion__row--open");
            });
        });
      })
    : "";
}

export default Accordions;
