import "intersection-observer";
import "./lib/picturefill";
import "./lib/ie-fixes";
import smoothscroll from "smoothscroll-polyfill";
import loadImgLazyloadScript from "./modules/lazyload-images";
import siteNavigationHandler from "./modules/site-navigation";
import searchHandler from "./modules/search-handler";
import handleCookieDialog from "./modules/cookie-dialog";
import sliderHandler from "./modules/sliders";
import newsletterSignup from "./modules/newsletter-signup";
import embedVideoPlayer from "./modules/embed-video-player";
import articleVideoHandler from "./modules/article-video-handler";
import loadPlaceholderImages from "./modules/image-placeholders";
import sneakpeakFormHandler from "./modules/sneakpeek-handler";
import SubmissionForm from "./modules/SubmissionForm";
import eventTracking from "./modules/event-tracking";
import initSpoiler from "./modules/spoiler";
import Accordions from "./modules/accordion";
import initBookVotes from "./modules/vote";

function loadJS() {
  smoothscroll.polyfill();
  document.createElement("picture");
  loadPlaceholderImages();
  siteNavigationHandler();
  searchHandler();
  handleCookieDialog();
  sliderHandler();
  newsletterSignup();
  embedVideoPlayer();
  articleVideoHandler();
  sneakpeakFormHandler();
  SubmissionForm();
  eventTracking();
  initSpoiler();
  Accordions();
  initBookVotes();

  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    if (!anchor.href.includes("#/sp?")) {
      anchor.addEventListener("click", function callback(e) {
        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth"
        });
      });
    }
  });

  // const request1 = new XMLHttpRequest()
  // const formData1 = new FormData()
  // const getMailchimpInterest = subscribeButton.getAttribute('data-list-type')
  // formData1.append('action', 'RegisterUser')
  // request1.open('POST', window.AJAX_URL, true)
  // request1.send(formData1)
}
loadImgLazyloadScript(window, document);

document.addEventListener("DOMContentLoaded", loadJS());
