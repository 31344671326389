const closeSpoiler = (spoiler) => {
  spoiler.classList.add('hidden')
}

const openSpoiler = (spoiler) => {
  spoiler.classList.remove('hidden')
}

const getSpoilerState = (spoiler) => {
  return spoiler.dataset.state
}

const setButtonText = (button, spoiler) => {
  const state = getSpoilerState(spoiler)
  const [CLOSED_TEXT, PENDING_TEXT, OPEN_TEXT] = [
    'Så slutade boken (spoiler!)',
    'Är du helt säker?',
    'Stäng spoilern'
  ]

  if (state === 'closed') {
    button.innerText = CLOSED_TEXT
  }
  else if (state === 'pending') {
    button.innerText = PENDING_TEXT
  }
  else if (state === 'open') {
    button.innerText = OPEN_TEXT
  }
}

const setSpoilerState = (spoiler, state) => {
  const [CLOSED, PENDING, OPEN] = ['closed', 'pending', 'open'];

  if (state === 'closed') {
    spoiler.dataset.state = PENDING
  }
  else if (state === 'pending') {
    spoiler.dataset.state = OPEN
  }
  else {
    spoiler.dataset.state = CLOSED
  }
}

const toggleSpoiler = (e) => {
  const spoiler = e.currentTarget.nextElementSibling
  const state = getSpoilerState(spoiler)

  if (state === 'pending') {
    openSpoiler(spoiler)
  } else if (state === 'open') {
    closeSpoiler(spoiler)
  }

  setSpoilerState(spoiler, getSpoilerState(spoiler))
  setButtonText(e.currentTarget, spoiler)
}


function initSpoiler() {
  const spoilerButtons = document.querySelectorAll('[data-js="BookSpoilerButton"]')

  if (!spoilerButtons || typeof spoilerButtons === 'undefined') {
    return
  }

  [...spoilerButtons].forEach((button) => {
    button.addEventListener('click', (e) => {
      toggleSpoiler(e)
    })
  })
}

export default initSpoiler
