import { wait } from 'js/utils'

const menuButtons = document.querySelectorAll('#SiteNavigationMenuButton')
const searchButtons = Array.from(document.querySelectorAll('.SiteNavigationSearchButton'))
const SiteNavigationMenu = document.getElementById('SiteNavigationMenu')
const SiteNavigation = document.getElementById('SiteNavigation')
const navHolder = document.getElementById('SiteNavigationMenuHolder')
const searchHolder = document.getElementById('SiteNavigationMenuSearch')

const subMenuButtons = document.querySelectorAll('.js-submenu-open');
const subMenuCloseButtons = document.querySelectorAll('.js-submenu-back');
const SiteNavigationSubMenuHolder = document.getElementById('SiteNavigationSubMenus');


// const SearchButton = document.getElementById('SiteNavigationSearchButton')
const bodyMenuOpenClass = 'navigation-menu-open'
const siteNavigationIsClosingClass = 'SiteNavigation--is-closing'
const siteNavigationMenuOpenClass = 'SiteNavigationMenu--open'
const menuButtonOpenClass = 'is-active'
let menuOpenState = false
let searchOpenState = false
let navigationOpenState = false

function closeNavigation() {
  navigationOpenState = false
  navHolder.classList.remove('block')
  navHolder.classList.add('hidden')
}

function openSearch() {
  closeMenu()
  searchOpenState = true
  // eslint-disable-next-line
  // const SearchFormInput = document.getElementById('SearchFormInput')
  wait(200)().then(() => document.getElementById('SearchFormInput').focus())
  searchHolder.classList.add('flex')
}
function closeSearch() {
  searchOpenState = false
  searchHolder.classList.remove('flex')
}
function openNavigation() {
  closeSearch();
  navHolder.classList.add('block')
  navHolder.classList.remove('hidden')
  navigationOpenState = true
}

function openMenu() {
  menuOpenState = true
  menuButtons.forEach((button) => { button.classList.add(menuButtonOpenClass) })
  SiteNavigationMenu.classList.add(siteNavigationMenuOpenClass)
  document.body.classList.add(bodyMenuOpenClass)
}

function closeMenu() {
  menuOpenState = false
  menuButtons.forEach((button) => { button.classList.remove(menuButtonOpenClass) })
  SiteNavigationMenu.classList.remove(siteNavigationMenuOpenClass)
  document.body.classList.remove(bodyMenuOpenClass)
  SiteNavigation.classList.add(siteNavigationIsClosingClass)
  closeSearch()
  closeNavigation()
  wait(2000)().then(() => SiteNavigation.classList.remove(siteNavigationIsClosingClass))
}

function closeSubMenu()
{
  SiteNavigationSubMenuHolder.classList.remove('active');
  SiteNavigationSubMenuHolder.querySelectorAll('ul.active').forEach((ul) => {
    ul.classList.remove('active');
  });
}

function openSubMenu(id)
{
  closeSubMenu();
  SiteNavigationSubMenuHolder.classList.add('active');
  SiteNavigationSubMenuHolder.querySelector(`#${id}`).classList.add('active');


}


function handleSearchToggle() {
  if (!searchButtons || typeof searchButtons === 'undefined' || searchButtons.length < 1) {
    return false
  }
  searchButtons.map((button) => {
    return button.addEventListener('click', () => {
      if (!searchOpenState) {

        openSearch()
      } else {
        closeMenu()
      }
    }, false)
  })

}

function handleMenuToggle() {
  if (!menuButtons || typeof menuButtons === 'undefined') {
    return false
  }
  return menuButtons.forEach((button) => {
    return button.addEventListener('click', () => {
      if (!menuOpenState) {
        openMenu()
        openNavigation()
      } else {
        closeMenu()
      }
    }, false)
  });
}

function handleSubMenuToggle()
{
  subMenuButtons.forEach((button) => {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      openSubMenu(button.dataset.menu)
    })
  });

  subMenuCloseButtons.forEach((button) => {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      closeSubMenu();
    });
  });
}

let prevScrollpos = window.pageYOffset

function hideOnScroll() {
  const currentScrollPos = window.pageYOffset

  if (currentScrollPos >= 100 && prevScrollpos < currentScrollPos) {
    document.querySelectorAll('.SiteNavigation')[0].classList.add('hide')
  } else {
    document.querySelectorAll('.SiteNavigation')[0].classList.remove('hide')
  }

  prevScrollpos = currentScrollPos
}

function siteNavigationHandler() {
  if (!SiteNavigationMenu || typeof SiteNavigationMenu === 'undefined') {
    return false
  } if (!navHolder || typeof navHolder === 'undefined') {
    return false
  } if (!searchHolder || typeof searchHolder === 'undefined') {
    return false
  }

  window.addEventListener('scroll', () => { hideOnScroll() })
  handleMenuToggle()
  handleSearchToggle()
  handleSubMenuToggle();
  return true
}
export default siteNavigationHandler
