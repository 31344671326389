import { getClosest } from 'js/utils'

const ajaxurl = window.AJAX_URL

const messageElement = (message, statusCode) => {
  const messageElem = document.createElement('span')
  const messageClass = 'NewsletterSignup___response-message'
  messageElem.id = `mailchimpResponseMessage__${parseInt(Math.random() * 1000)}`
  messageElem.classList.add(messageClass, 'mt-4', 'block')

  if (statusCode === 200) {
    messageElem.classList.add(`${messageClass}--success`, 'text-purple')
  } else {
    messageElem.classList.add(`${messageClass}--fail`, 'text-purple')
  }

  messageElem.innerHTML = message
  return messageElem
}

const doSubscription = (emailAddress, formElement) => {
  const responseMessageHolder = formElement.querySelector('.NewsletterSignup__response-message-holder')
  responseMessageHolder.innerHTML = ''
  const subscribeButton = formElement.querySelector('button.NewsletterSignup__submit-button')
  const request = new XMLHttpRequest()
  const formData = new FormData()
  const getMailchimpInterest = subscribeButton.getAttribute('data-list-type')


  formData.append('action', 'RegisterUser')
  formData.append('email', emailAddress)
  formData.append('interest', getMailchimpInterest)

  request.onreadystatechange = () => {
    if (request.readyState === 1) {
      subscribeButton.setAttribute('disabled', 'disabled')
    }

    if (request.readyState === 4) {
      subscribeButton.removeAttribute('disabled')
      if (request.status === 200) {
        const res = JSON.parse(request.responseText)
        // reset form if success
        if (res.statusCode === 200) formElement.reset()
        const msg = messageElement(res.message, res.statusCode)
        responseMessageHolder.appendChild(msg)
      } else {
        console.log(`ERROR: ${request.status} ${request.statusText}`)
      }
    }
  }

  request.open('POST', ajaxurl, true)
  request.send(formData)
}

const hasAcceptedTerms = (e, submitButton) => {
  submitButton.disabled = !e.currentTarget.checked
  return e.currentTarget.checked
}

const submitForm = (e, formElement, inputElement) => {
  e.preventDefault()
  if (inputElement.value) {
    return doSubscription(inputElement.value, formElement)
  }
  return false
}

const inputHandler = (inputElement, formElement) => {
  if (inputElement.value.length >= 1) {
    formElement.classList.add('has-value')
    inputElement.classList.add('has-value')
  } else {
    formElement.classList.remove('has-value')
    inputElement.classList.remove('has-value')
  }
}

function newsletterSignup() {
  const newsletterForms = Array.from(document.querySelectorAll('form.NewsletterSignup__form'))
  if (!newsletterForms || newsletterForms.length < 1 || typeof newsletterForms === 'undefined') return false

  return newsletterForms.map((form) => {
    const inputElement = form.querySelector('input.NewsletterSignup__input')
    if (!inputElement) return false
    inputElement.onkeyup = () => inputHandler(inputElement, form)

    form.querySelector('input.NewsletterSignup__terms-checkbox').addEventListener('click', e => hasAcceptedTerms(e, form.querySelector('.NewsletterSignup__submit-button')))

    if (form.addEventListener) {
      form.addEventListener('submit', e => submitForm(e, form, inputElement), false)
    } else if (form.attachEvent) {
      form.attachEvent('onsubmit', e => submitForm(e, form, inputElement))
    }
    return true
  })
}

export default newsletterSignup
