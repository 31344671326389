import $ from 'jquery';

function logEvent(category, action, label, value)
{
  if(typeof ga === 'function')
  {
    var command = '';

    if(typeof google_tag_manager !== 'undefined')
      command = ga.getAll()[0].get('name') + '.';

    command += 'send';

    ga(command, 'event', {
      'eventCategory': category,
      'eventAction': action,
      'eventLabel': label,
      'eventValue': typeof value === 'undefined' ? 1 : value
    });
  }
  else
    console.log('ga() not defined');
}

function eventTracking () {
	// Google analytics
	$('.site-tagline').on('click', function() {
		logEvent('Click', 'Click Tagline');
	})

    $('.SiteNavigation__logotype').on('click', function () {
		logEvent('Click', 'Click Site Logo');
	})

	$('#SiteNavigationSearchButton').on('click', function() {
		logEvent('Click', 'Click Search Button');
	})

	$('#SiteNavigationMenuButton').on('click', function() {
		logEvent('Click', 'Click Menu Button');
	})

	$('.StartPageHeader_link').on('click', function(e) {
		let url = $(event.currentTarget).attr('href')

		logEvent('Click', 'Click Cover Article', url);
	})

	$('.ArticleLinkBlock').on('click', function(e) {
		let url = $(event.currentTarget).attr('href')

		logEvent('Click', 'Click Article Module', url);
	})

	$('.NewsletterSignup__submit-button').on('click', function(e) {
		logEvent('Click', 'Clicked on newsletter button')
	})

	$('.facebook-link-ga').on('click', function(e) {
		logEvent('Click', 'Clicked on facebook link');
	})

	$('.instagram-link-ga').on('click', function(e) {
		logEvent('Click', 'Clicked on instagram icon link');
	})

	$('.slider-link').on('click', function(e) {
		let url = $(event.currentTarget).attr('href')

		logEvent('Click', 'Click on slider booktip', url);
	})

	$('.menu-link-ga').on('click', function(e) {
		let url = $(event.currentTarget).attr('href')

		logEvent('Click', 'Menu click', url);
	})

	$('.article__share').on('click', function(event) {
		logEvent('Click', 'Share', 'Shared article on facebook');
	});

	let searchInput = $('#SearchFormInput');

	$('#SearchFormInput').on('click', function(event) {
		logEvent('Click', 'Search', 'Click on search field');
	});

	$("#SearchFormInput").keypress(function() {
		logEvent('Click', 'Search', 'Started typing in search field');
	});

	$("#SearchResultSorting").change(function() {
	   let searchBy = $(this).children("option:selected").val();

	   logEvent('Click', 'Search', 'Sorted search results', searchBy);
   });

   $('.pagination-ga').on('click', function(event) {
	   let paginationIndex = $(event.currentTarget).attr('data-id')

	   logEvent('Click', 'Click - next page in category', 'Clicked on pagination link', paginationIndex);
   });

   // Facebook events

	const newsletterInput = $('.NewsletterSignup__input');

	newsletterInput.on('click', function(event) {
		fbq('trackCustom', 'Clicked newsletter input');
	});

	newsletterInput.on('keyup', function(event) {
		fbq('trackCustom', 'Stopped typing email in newsletter input');
	});

	newsletterInput.on('keydown', function(event) {
		fbq('trackCustom', 'Started typing email in newsletter input');
	});

	$('.Sneakpeak__input').on('keydown', function(event) {
		fbq('trackCustom', 'Started typing email in sneakpeek input');
	});

	$('.Sneakpeek_download-link').on('click', function(event) {
		fbq('trackCustom', 'Downloaded article file');
	});
}

export default eventTracking;
