import { wait } from 'js/utils'
import Loader from 'js/utils/loader'

const { AJAX_URL } = window

const searchForm = document.getElementById('SearchForm')
const searchField = document.getElementById('SearchFormInput')
const searchResultsHolder = document.getElementById('SearchResultsHolder')
const SearchResultsInfoHolder = document.getElementById('SearchResultsInfoHolder')
const SearchResultSortingHolder = document.getElementById('SearchResultSortingHolder')
const SearchResultSorting = document.getElementById('SearchResultSorting')
const searchResultsHolderIsSearchingClass = 'SearchResultsHolder--loading'

const defaultInfoText = 'Var vänlig ange ett sökord på mer än 2 tecken'
// SearchResultsInfoHolder.innerHTML = defaultInfoText
let delay = 0
let loader
let allowSorting = false

const SEARCH = {
  resInternal: '',
  infoInternal: defaultInfoText,
  // eslint-disable-next-line
  resultsListener(val) {},
  // eslint-disable-next-line
  infoListener(val) {},
  set results(val) {
    this.resInternal = val
    this.resultsListener(val)
  },
  get results() {
    return this.resInternal
  },
  set infoText(val) {
    this.infoInternal = val
    this.infoListener(val)
  },
  get infoText() {
    return this.infoInternal
  },
  registerResultListener(listener) {
    this.resultsListener = listener
  },
  registerInfoListener(listener) {
    this.infoListener = listener
  }
}

const sortResultsByTitle = (res1, res2) => {
  let retVal
  if (res1.title > res2.title) retVal = 1
  if (res1.title < res2.title) retVal = -1
  return retVal
}
const sortResultsByRelevance = (res1, res2) => {
  let retVal
  if (res1.relevance < res2.relevance) retVal = 1
  if (res1.relevance > res2.relevance) retVal = -1
  return retVal
}
const sortResultsByDate = (res1, res2) => {
  let retVal
  const date1 = new Date(res1.date)
  const date2 = new Date(res2.date)
  if (date1 < date2) retVal = 1
  if (date1 > date2) retVal = -1
  return retVal
}

function disableSortingElement() {
  allowSorting = false
  return SearchResultSortingHolder.classList.add('opacity-25', 'pointer-events-none', 'cursor-not-allowed')
}
function enableSortingElement() {
  allowSorting = true
  return SearchResultSortingHolder.classList.remove('opacity-25', 'pointer-events-none', 'cursor-not-allowed')
}
// render html of response
function renderResultsHtml() {
  searchResultsHolder.innerHTML = ''

  if (typeof SEARCH.results === 'object' && SEARCH.results.length > 0) {
    searchResultsHolder.classList.add(searchResultsHolderIsSearchingClass)
    const resString = SEARCH.results.map((r) => {
      const { html } = r
      return html
    })
    searchResultsHolder.insertAdjacentHTML('afterbegin', resString.join(''))
    wait(800)()
      .then(() => {
        searchResultsHolder.classList.remove(searchResultsHolderIsSearchingClass)
        enableSortingElement()
      })
  } else if (SEARCH.results === 'undefined' || typeof SEARCH.results === 'undefined' || !SEARCH.results) {
    searchResultsHolder.innerHTML = ''
    disableSortingElement()
  } else {
    disableSortingElement()
    searchResultsHolder.innerHTML = ''
  }
}
function renderResponseInfoHtml() {
  SearchResultsInfoHolder.innerHTML = ''

  if (typeof SEARCH.infoText === 'object') {
    SearchResultsInfoHolder.insertAdjacentHTML('afterbegin', SEARCH.infoText)
  } else if (SEARCH.infoText === 'undefined' || typeof SEARCH.infoText === 'undefined' || !SEARCH.infoText) {
    SearchResultsInfoHolder.innerHTML = ''
  } else {
    SearchResultsInfoHolder.innerHTML = SEARCH.infoText
  }
}
// eslint-disable-next-line
function sortResults(sortVal) {
  //
  if (!SEARCH.results || typeof SEARCH.results === 'undefined' || allowSorting === false) {
    return false
  }
  const sort = sortVal
  const result = SEARCH.results
  if (sort === 'relevance') {
    SEARCH.results = result.sort(sortResultsByRelevance)
  } else if (sort === 'title') {
    SEARCH.results = result.sort(sortResultsByTitle)
  } else if (sort === 'date') {
    SEARCH.results = result.sort(sortResultsByDate)
  } else {
    SEARCH.results = result.sort(sortResultsByRelevance)
  }
}

// do search
function doAjaxSearch(val) {
  const request = new XMLHttpRequest()
  const formData = new FormData()
  formData.append('action', 'ajaxSearch')
  formData.append('queryString', val)

  request.onreadystatechange = () => {
    if (request.readyState === 1) {
      // start loader here
      searchResultsHolder.classList.add(searchResultsHolderIsSearchingClass)
      loader.show()
    }
    // it's done
    if (request.readyState === 4) {
      // remove loader here
      loader.hide()
      setTimeout(() => { searchResultsHolder.classList.remove(searchResultsHolderIsSearchingClass) }, 800)
      // it's successful
      if (request.status === 200) {
        const res = JSON.parse(request.responseText)
        SEARCH.results = res.result
        SEARCH.infoText = res.infoHtml
      } else {
        // handleError
        // eslint-disable-next-line
        console.log(`ERROR: ${request.status} ${request.statusText}`)
      }
    }
  }

  request.open('POST', AJAX_URL, true)
  request.send(formData)
}

function allowedKeypresses(k) {
  if (k === 20 /* Caps lock */
   || k === 13 /* Enter */
   || k === 9 /* Tab */
   || k === 27 /* Escape Key */
   || k === 17 /* Control Key */
   || k === 91 /* Windows Command Key */
   || k === 19 /* Pause Break */
   || k === 18 /* Alt Key */
   || k === 93 /* Right Click Point Key */
   || (k >= 35 && k <= 40) /* Home, End, Arrow Keys */
   || k === 45 /* Insert Key */
   || (k >= 33 && k <= 34) /* Page Down, Page Up */
   || (k >= 96 && k <= 111)
   || (k >= 112 && k <= 123) /* F1 - F12 */
   || (k >= 144 && k <= 145)) { /* Num Lock, Scroll Lock */
    return false
  }
  return true
}
// handle keypresses
// eslint-disable-next-line
function handleKeypress(event, val) {
  if (val.length > 0 && allowedKeypresses(event.which)) {
    clearTimeout(delay)
    if (val.length < 3) {
      SEARCH.infoText = defaultInfoText
    } else {
      delay = setTimeout(() => doAjaxSearch(val), 300)
    }
  } else if (!val.length) {
    // SEARCH.results = ''
  } else {
    return false
  }
}
function preventFormSubmit() {
  if (searchForm.addEventListener) {
    searchForm.addEventListener('submit', e => e.preventDefault(), false)
  } else if (searchForm.attachEvent) {
    searchForm.attachEvent('onsubmit', e => e.preventDefault())
  }
}

// eslint-disable-next-line
function searchHandler() {
  if (
    (!searchField || typeof searchField === 'undefined')
    || (!searchResultsHolder || typeof searchResultsHolder === 'undefined')
    || (!searchForm || typeof searchForm === 'undefined')) {
    return false
  }
  disableSortingElement()
  const loaderHolder = document.createElement('div')
  loaderHolder.id = 'LoaderHolder'
  loaderHolder.classList.add('block', 'w-full')
  searchResultsHolder.parentNode.prepend(loaderHolder)
  loader = new Loader(loaderHolder)

  preventFormSubmit()
  searchField.addEventListener('keyup', (e => handleKeypress(e, searchField.value)), false)
  searchField.addEventListener('touchend', (e => handleKeypress(e, searchField.value)), false)

  SearchResultSorting.addEventListener('change', e => sortResults(e.target.value), false)
  // rerender the result html when it changes
  /* eslint-disable */
  SEARCH.registerResultListener(newResult => renderResultsHtml())
  SEARCH.registerInfoListener(newResult => renderResponseInfoHtml())
  /* eslint-enable */
}

export default searchHandler
