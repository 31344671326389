import Flickity from 'flickity'
import 'flickity/dist/flickity.min.css'
require('flickity-imagesloaded');


function bookTipsSliders() {
  const sliders = Array.from(document.querySelectorAll('.BookTipsSlider__js-slider'))
  if (!sliders || sliders.length < 1 || typeof sliders === 'undefined') {
    return false
  }
  // eslint-disable-next-line
    return sliders.map(slider => {

      const flkty = new Flickity(slider, {
        cellSelector: '.BookTipsSlider__slide-item',
        wrapAround: false,
        prevNextButtons: true,
        pageDots: false,
        cellAlign: 'left',
        contain: true,
        setGallerySize: true,
        imagesLoaded: true
      })



      return flkty
  })
}

function imageLinkSliders() {
  const sliders = Array.from(document.querySelectorAll('[data-js="imageLinkSlider"]'));
  if (!sliders || sliders.length < 1 || typeof sliders === 'undefined') {
    return false
  }
  // eslint-disable-next-line
    return sliders.map(slider => {
    slider.classList.remove('slider-hidden')
    // eslint-disable-next-line
    slider.offsetHeight

    const flkty = new Flickity(slider, {
      cellSelector: '.ImageLinkSlider-slide',
      wrapAround: false,
      prevNextButtons: true,
      pageDots: false,
      cellAlign: 'left'
    })

    return flkty
  })
}

// eslint-disable-next-line
function sliderHandler() {
  bookTipsSliders()
  imageLinkSliders()
}
export default sliderHandler
